/* Timeline custom component */
.timeline {
}

.timeline-card {
  position: relative;
  margin-left: 16px;
}

.timeline-card:before {
  content: '';
  display: inline-block;
  position: absolute;
  background-color: #fff;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  top: 16px;
  left: -12px;
  border: 5px solid;
  z-index: 2;
}

.timeline-body {
  border-left: 2px solid #E6E9ED;
}

.timeline-card-primary:before {
  border-color: var(--bs-primary);
}

.timeline-card-info:before {
  border-color: var(--bs-info);
}

.timeline-card-secondary:before {
  border-color: var(--bs-secondary);
}

.timeline-card-success:before {
  border-color: var(--bs-teal);
}